import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getProductTypeCapacityReport } from '../modules/prodplanningdata';

const colors = ['#1A9C9F', '#1A5A9F', '#376FAB', '#9B70C3', '#C370C1', '#B75383', '#B7535C', '#B7AF53', '#AF53B7'];
const colorFree = '#FFFFFF';

const chartLabelTooltip = (tooltipItem, tooltipData, t) => {
    const tooltip = tooltipData[tooltipItem.datasetIndex];
    return `${tooltip.label}: ${tooltip.reserved[tooltipItem.index]} / ${
        tooltip.allocated[tooltipItem.index] + tooltip.reserved[tooltipItem.index]
    } ${t('pcs')}`;
};

const chartTitleTooltip = (tooltipItem, tooltipData) => {
    return tooltipData[tooltipItem[0].datasetIndex].label;
};

//const chartTooltipFooter = (tooltipItem, tooltipData, t) => {
//    const tooltip = tooltipData[tooltipItem[0].datasetIndex];
/*return `${t('production.capacity')} ${tooltip.dailyTotalReserved[tooltipItem[0].index]} / ${
        tooltip.dailyTotalAllocated[tooltipItem[0].index]
    } ${t('pcs')} `;*/
//    return '';
//};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
    },
    btn: {
        margin: '0.5em',
    },
}));

const dateRange = { start: moment().subtract(1, 'month'), end: moment().add(6, 'month') };

const ProductionCapacityReport2 = (props) => {
    const { t } = useTranslation();
    const active = props.active;
    const plotWidth = window.innerWidth - 100;
    const yAxisTitle = t('production.capacity') + ' ' + t('pcs');
    const xAxisTitle = t('offerOrProductList.week');
    const classes = useStyles();
    const [chartData, setChartData] = useState(null);
    const [chartTooltipData, setChartTooltipData] = useState(null);
    const [reportData, setReportData] = React.useState(null);

    useEffect(() => {
        function generateProductTypeReportData(report, color, colorAvail, start, end) {
            const runningDate = moment(start);
            const dataset = {
                label: report.productType.name,
                data: [],
                backgroundColor: color,
                borderColor: 'black',
                borderWidth: { bottom: 1, left: 2, right: 2, top: 1 },
            };
            const tooltip = {
                label: dataset.label,
                reserved: [],
                allocated: [],
            };
            const datasetAvail = {
                label: report.productType.name + t('reports.free'),
                data: [],
                backgroundColor: colorAvail,
                borderColor: 'black',
                borderWidth: { bottom: 1, left: 2, right: 2, top: 1 },
            };
            do {
                let usage = 0;
                let allocationsAvailable = 0;
                const weeklyData = report.capacities.find((c) => c.week === runningDate.format('YYYYww'));
                if (weeklyData) {
                    usage = weeklyData.usedCapacity;
                    allocationsAvailable = weeklyData.capacity - weeklyData.usedCapacity;
                }
                dataset.data.push(usage);
                datasetAvail.data.push(allocationsAvailable);
                tooltip.reserved.push(usage);
                tooltip.allocated.push(allocationsAvailable);
                runningDate.add(1, 'week');
            } while (runningDate.isSameOrBefore(end, 'week'));

            return {
                datasets: [dataset, datasetAvail],
                tooltips: [tooltip, tooltip],
            };
        }

        async function loadReportData() {
            var reportDat = await getProductTypeCapacityReport();
            if (reportDat) setReportData(reportDat);
        }

        if (active && !reportData) {
            loadReportData();
        }
        if (active && reportData) {
            const start = dateRange.start;
            const runningDate = moment(start);
            const end = dateRange.end;
            const labels = [];
            const tooltips = [];
            const datasets = [];
            let ind = 0;
            let prodTypeInd = 0;

            do {
                labels[ind] = runningDate.week();
                ind++;
                runningDate.add(1, 'week');
            } while (runningDate.isSameOrBefore(end, 'week'));

            for (const r of reportData) {
                var dataSetsArr = generateProductTypeReportData(r, colors[prodTypeInd], colorFree, start, end);
                datasets.push.apply(datasets, dataSetsArr.datasets);
                tooltips.push.apply(tooltips, dataSetsArr.tooltips);
                prodTypeInd++;
            }

            setChartData({ labels, datasets });
            setChartTooltipData(tooltips);
        }
    }, [active, t, reportData]);

    if (!chartData) return null;
    if (!active) return null;

    return (
        <Grid container className={classes.root}>
            {chartData != null && (
                <Grid item>
                    <Bar
                        data={chartData}
                        width={plotWidth}
                        height={500}
                        options={{
                            scales: {
                                xAxes: [
                                    {
                                        stacked: true,
                                        scaleLabel: {
                                            display: true,
                                            labelString: xAxisTitle,
                                        },
                                    },
                                ],
                                yAxes: [
                                    {
                                        stacked: true,
                                        scaleLabel: {
                                            display: true,
                                            labelString: yAxisTitle,
                                        },
                                        ticks: {
                                            beginAtZero: true,
                                            callback: (value, index, values) => {
                                                return value.toLocaleString();
                                            },
                                        },
                                    },
                                ],
                            },
                            legend: {
                                display: true,
                            },
                            tooltips: {
                                callbacks: {
                                    label: (tooltipItem) => {
                                        return chartLabelTooltip(tooltipItem, chartTooltipData, t);
                                    },
                                    title: (tooltipItem) => {
                                        return chartTitleTooltip(tooltipItem, chartTooltipData);
                                    },
                                    /*footer: (tooltipItem) => {
                                        return chartTooltipFooter(tooltipItem, chartTooltipData, t);
                                    },*/
                                },
                            },
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default ProductionCapacityReport2;
