import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import SaveButton from '../common/savebutton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { cloneDeep } from 'lodash';
import {
    productTypeSaveCapacityAllocations,
    productTypeLoadCapacityAllocations,
    getProductTypes,
    upsertProductType,
    refreshProductTypes as refreshProductTypesFromServer,
} from '../modules/prodplanningdata';
import { buildCapacityByProductTypeAndWeekData } from './weeklycapacitydatabuilder';
import {
    formatWeekIdentifierDayjs,
    getWeekFromIdentifier,
    getYearFromIdentifier,
    generateContinuousWeekList,
} from '../order/capacityplanner';
import ProductTypeCapacityAllocation from '../domain/producttypecapacityallocation';
import ProductType from '../domain/producttype';
import { useTranslation } from 'react-i18next';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import TextEditBlock from '../invoice/texteditblock';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    paper: {
        padding: '5px',
    },
    prodcategories: {
        marginTop: '1em',
        marginBottom: '1em',
    },
    accordionDetails: {
        display: 'block',
    },
    small: {
        fontSize: '0.75em',
    },
    textEdit: {},
    btn: {
        margin: theme.spacing(1),
    },
}));

const ProductionPlanning2 = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const capacityAllocationsByWeek = useSelector((state) => state.prodplanningdata.productTypeCapacityAllocations);
    const loadingCapacityData = useSelector((state) => state.prodplanningdata.productTypeCapacityAllocationsLoading);
    const productTypes = useSelector((state) => state.prodplanningdata.productTypes);
    const productTypesLoading = useSelector((state) => state.prodplanningdata.productTypesLoading);
    const [edit, setEdit] = useState(false);

    // mutated data for view. Contains data from backend & possible changes by user
    const [weeklyCapacitiesPerType, setWeeklyCapacitiesPerType] = useState({});
    //const [urgentCapacity, setUrgentCapacity] = useState({});

    // contains item changes by user that are sent to backend in case data is saved
    const [changedItems, setChangedItems] = useState([]);

    const [weekList, setWeekList] = useState([]);
    const [startWeek, setStartWeek] = useState(null);
    const [endWeek, setEndWeek] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [hasChanges, setHasChanges] = useState([]);
    const [saving, setSaving] = useState(false);
    const [changingSelection, setChangingSelection] = useState(false);

    const [productTypeEdit, setProductTypeEdit] = useState({});
    const [productTypeSearchPreview, setProductTypeSearchPreview] = useState({});

    const { t } = useTranslation();

    const products = useSelector((state) => state.productdata.filterProducts);

    // used to build the data for UI
    // inputs: backend data (capacityAllocationsByWeek) + potential changes by user (changedItems)
    useEffect(() => {
        if (!productTypes && !productTypesLoading) {
            getProductTypes()(dispatch);
        }
        if (
            !productTypes ||
            !capacityAllocationsByWeek ||
            Object.keys(capacityAllocationsByWeek).length === 0 ||
            weekList.length === 0
        ) {
            return;
        }
        const capacityData = buildCapacityByProductTypeAndWeekData(
            capacityAllocationsByWeek,
            productTypes,
            weekList,
            changedItems
        );

        setWeeklyCapacitiesPerType(capacityData.capacityPerProductType);
        setChangingSelection(false);
    }, [capacityAllocationsByWeek, productTypes, weekList, changedItems, dispatch, productTypesLoading]);

    // used only to populate initial week list
    useEffect(() => {
        if (weekList.length === 0) {
            const start = dayjs().subtract(1, 'months');
            const end = dayjs().add(4, 'months');
            setStartWeek(`${formatWeekIdentifierDayjs(start)}`);
            setEndWeek(`${formatWeekIdentifierDayjs(end)}`);
            setStartDate(start);
            setEndDate(end);
            setWeekList(
                generateContinuousWeekList(formatWeekIdentifierDayjs(start), formatWeekIdentifierDayjs(end), true)
            );
        }
    }, [weekList.length]);

    useEffect(() => {
        if (changedItems && changedItems.length > 0) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    }, [changedItems]);

    // makes sure that we have loaded all necessary data from backend
    useEffect(() => {
        if (loadingCapacityData) return;

        if (weekList && weekList.length > 0 && startWeek && endWeek && capacityAllocationsByWeek) {
            const sortedWeeks = weekList.sort();
            if (
                !capacityAllocationsByWeek[sortedWeeks[0]] ||
                !capacityAllocationsByWeek[sortedWeeks[sortedWeeks.length - 1]]
            ) {
                dispatch(productTypeLoadCapacityAllocations(startDate, endDate));
            } else {
                setChangingSelection(false);
            }
        } else if (
            weekList &&
            weekList.length &&
            Object.keys(capacityAllocationsByWeek).length === 0 &&
            startWeek &&
            endWeek
        ) {
            dispatch(productTypeLoadCapacityAllocations(startDate, endDate));
        }
    }, [weekList, capacityAllocationsByWeek, startWeek, endWeek, dispatch, loadingCapacityData, startDate, endDate]);

    useEffect(() => {
        if (products && products.length > 0 && productTypes && productTypes.length > 0) {
            const previews = {};
            const others = productTypes.find((t) => t.name === 'Muut');
            let sumOfTypes = 0;
            for (const prodType of productTypes) {
                // eslint-disable-next-line no-loop-func
                var matches = products.filter((p) => p.type === prodType.name);
                previews[prodType.id] = matches.length;
            }
            for (const k of Object.keys(previews)) {
                sumOfTypes += previews[k];
            }
            previews[others.id] = products.length - sumOfTypes;
            setProductTypeSearchPreview(previews);
        }
    }, [products, productTypes]);

    const onChange = (e, productTypeId, yearAndWeek) => {
        const year = getYearFromIdentifier(yearAndWeek);
        const week = getWeekFromIdentifier(yearAndWeek);
        const newValue = parseInt(e.target.value);
        const productType = productTypes.find((c) => c.id === productTypeId);
        const weeklyCapaForType = capacityAllocationsByWeek[yearAndWeek].find(
            (a) => a.productType.id === productTypeId
        );
        const newItems = cloneDeep(changedItems);
        const indInChangedItems = newItems.findIndex(
            (a) => a.productType.id === productTypeId && a.year === year && a.week === week
        );

        if (
            (weeklyCapaForType && weeklyCapaForType.allocatedCapacity !== newValue) ||
            (!weeklyCapaForType && newValue > 0)
        ) {
            // 1) there is capacity configured in backend data and we're about to change it
            //   --> add the changed value to "changedItems" state variable.
            //
            // 2) There is no configured capacity for the given portfolio and week -> add/update item
            //
            if (indInChangedItems !== -1) {
                newItems.splice(indInChangedItems, 1);
            }
            newItems.push(
                new ProductTypeCapacityAllocation({
                    productType,
                    year,
                    week,
                    allocatedCapacity: newValue,
                })
            );
        } else if ((weeklyCapaForType && weeklyCapaForType.allocatedCapacity === newValue) || !weeklyCapaForType) {
            // 1) there is capacity configured in the backend data and it is the same as
            // the edited value --> Make sure any change object are removed from "changedItems"
            //
            // 2) There is no configured capacity in backend for this case, and the value that is edited is null/0/empty
            //    --> make sure change item is cleaned out
            if (indInChangedItems !== -1) {
                newItems.splice(indInChangedItems, 1);
            }
        }
        setChangedItems(newItems);
    };

    const cancelEdit = () => {
        setChangedItems([]);
        setEdit(false);
    };

    const saveChanges = async () => {
        setSaving(true);
        await productTypeSaveCapacityAllocations(changedItems)(dispatch);
        setChangedItems([]);
        setSaving(false);
    };

    const changeSelection = async (months) => {
        setChangingSelection(true);
        const start = startDate.add(months, 'months');
        const end = endDate.add(months, 'months');
        setStartWeek(`${formatWeekIdentifierDayjs(start)}`);
        setEndWeek(`${formatWeekIdentifierDayjs(end)}`);
        setStartDate(start);
        setEndDate(end);
        setWeekList(generateContinuousWeekList(formatWeekIdentifierDayjs(start), formatWeekIdentifierDayjs(end), true));
    };

    const refreshProductTypes = async () => {
        await refreshProductTypesFromServer()(dispatch);
    };

    const editProductType = (id) => {
        const edits = {};
        edits[id] = productTypes.find((t) => t.id === id);
        setProductTypeEdit(edits);
    };

    const onChangeProdType = (e) => {
        const newValue = e.target.value;
        const field = e.target.name;
        const id = Object.keys(productTypeEdit)[0];
        const edits = cloneDeep(productTypeEdit);
        edits[id][field] = newValue;
        setProductTypeEdit(edits);
    };

    if (!weeklyCapacitiesPerType || !productTypes) return null;

    return (
        <Grid item xs={12}>
            <Grid item xs={12} className={classes.prodcategories}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <strong>
                                    {t('admin.productTypes')} ({productTypes.length})
                                </strong>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('admin.productType')}</TableCell>
                                    <TableCell>Min toimitusaika pv</TableCell>
                                    <TableCell>Toimitusaika lisäys pv</TableCell>
                                    <TableCell>Tuotteita kpl</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productTypes.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.name}</TableCell>
                                        {!productTypeEdit[item.id] && (
                                            <TableCell>{item.minimumDeliveryTimeInDays}</TableCell>
                                        )}
                                        {productTypeEdit[item.id] && (
                                            <TableCell>
                                                <TextEditBlock
                                                    name="minimumDeliveryTimeInDays"
                                                    className={classes.textEdit}
                                                    value={productTypeEdit[item.id].minimumDeliveryTimeInDays}
                                                    number={true}
                                                    onChange={onChangeProdType}
                                                />
                                            </TableCell>
                                        )}
                                        {!productTypeEdit[item.id] && (
                                            <TableCell>{item.additionalDeliveryDelayInDays}</TableCell>
                                        )}
                                        {productTypeEdit[item.id] && (
                                            <TableCell>
                                                <TextEditBlock
                                                    name="additionalDeliveryDelayInDays"
                                                    className={classes.textEdit}
                                                    value={productTypeEdit[item.id].additionalDeliveryDelayInDays}
                                                    number={true}
                                                    onChange={onChangeProdType}
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell>{productTypeSearchPreview[item.id]}</TableCell>
                                        {!productTypeEdit[item.id] && (
                                            <TableCell>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => editProductType(item.id)}
                                                    size={'small'}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        {productTypeEdit[item.id] && (
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.btn}
                                                    onClick={async () => {
                                                        await upsertProductType(productTypeEdit[item.id])(dispatch);
                                                        setProductTypeEdit({});
                                                    }}>
                                                    {t('buttons.save')}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    className={classes.btn}
                                                    onClick={() => {
                                                        setProductTypeEdit({});
                                                    }}>
                                                    {t('buttons.cancel')}
                                                </Button>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <br />
                        <Grid item xs={12}>
                            <IconButton onClick={refreshProductTypes} disabled={productTypesLoading}>
                                <RefreshIcon />
                            </IconButton>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('admin.productType')}</TableCell>
                            {weekList.map((w) => (
                                <TableCell key={w}>{w}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productTypes.map((p) => (
                            <TableRow key={p.id}>
                                <TableCell component="th" scope="row">
                                    {p.name}
                                </TableCell>
                                {!edit &&
                                    weekList.map((w) => (
                                        <TableCell key={w}>
                                            {weeklyCapacitiesPerType && weeklyCapacitiesPerType[p.id] && (
                                                <span>
                                                    {weeklyCapacitiesPerType[p.id].capacities[w]
                                                        ? weeklyCapacitiesPerType[p.id].capacities[w]
                                                        : '0'}
                                                </span>
                                            )}
                                        </TableCell>
                                    ))}
                                {edit &&
                                    weekList.map((w) => (
                                        <TableCell key={w}>
                                            {weeklyCapacitiesPerType && weeklyCapacitiesPerType[p.id] && (
                                                <Input
                                                    value={
                                                        weeklyCapacitiesPerType[p.id].capacities[w]
                                                            ? weeklyCapacitiesPerType[p.id].capacities[w]
                                                            : 0
                                                    }
                                                    onChange={(e) => onChange(e, p.id, w)}
                                                    name={p.id + w}
                                                />
                                            )}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Footer>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="previous"
                        disabled={changingSelection}
                        onClick={() => changeSelection(-3)}
                        startIcon={<SkipPreviousIcon />}>
                        -3kk
                    </Button>
                </Grid>
                {!edit && (
                    <Grid item>
                        <Button variant="contained" color="primary" name="btn-edit" onClick={() => setEdit(true)}>
                            <i className="fas fa-pencil-alt" />
                            &nbsp;{t('buttons.edit')}
                        </Button>
                    </Grid>
                )}
                {edit && (
                    <Grid item>
                        <SaveButton
                            disabled={!hasChanges || saving || changingSelection}
                            hasChanges={hasChanges}
                            onSubmit={saveChanges}
                            saving={saving}
                        />
                    </Grid>
                )}
                {edit && (
                    <Grid item>
                        <Button variant="contained" color="primary" name="btn-cancel" onClick={cancelEdit}>
                            {t('buttons.cancel')}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="next"
                        disabled={changingSelection}
                        onClick={() => changeSelection(3)}
                        endIcon={<SkipNextIcon />}>
                        +3kk
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </Grid>
    );
};

export default ProductionPlanning2;
