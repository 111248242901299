import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import ObjectIdGenerator from '../modules/objectidgenerator';
import { verifyAndReturnDecimal } from '../common/common';
import {
    saveNewProduct,
    updateProduct,
    getProductUsage,
    deleteProduct,
    replaceProduct,
    mergeProducts,
} from '../modules/productdata';
import SaveButton from '../common/savebutton';
import DeleteButton from '../common/deletebutton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import FilterProduct from '../domain/filterproduct';
import ConfirmationDialog from '../common/confirmationdialog';
import { ParseProductDimension, IsProductStandard } from '../common/productdataparse';
import AddIcon from '@material-ui/icons/Add';
import AddNoteDialog from '../customer/addnotedialog';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
    },
    withPadding: {
        padding: '1em',
    },
    alertFullWidth: {
        width: '100%',
    },
    chip: {
        margin: '0.5em',
    },
    notesHeader: {
        margin: '0.75em',
    },
    h5lessMargin: {
        margin: '0.75em',
    },
}));

export default function ProductEditView(props) {
    const { productId } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const products = useSelector((state) => state.productdata.filterProducts);
    const [productIdInt, setProductIdInt] = useState(productId && productId !== 'new' ? parseInt(productId) : null);
    const [productUsage, setProductUsage] = useState(null);
    const [saving, setSaving] = useState(false);
    const [productNotFound, setProductNotFound] = useState(false);
    const [product, setProduct] = useState({
        priceWithoutVat: '0',
        fabricSurfaceArea: '0',
        productName: ' ',
        productCode: '0',
        productionPrice: '0',
    });
    const [showLocationsUsingProduct, setShowLocationsUsingProduct] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [duplicates, setDuplicates] = useState([]);
    const [productDimensions, setProductDimensions] = useState(null);
    const [productNonStandard, setProductNonStandard] = useState(false);
    const [showNoteDialog, setShowNoteDialog] = useState(false);
    const [noteToEdit, setNoteToEdit] = useState(null);
    const suggestions = useSelector((state) => state.productdata.allProductNotes);
    const { t } = useTranslation();

    useEffect(() => {
        async function getId() {
            const id = await ObjectIdGenerator.newId();
            setProductIdInt(id);
            const prod = new FilterProduct();
            prod.productCode = '0';
            prod.id = id;
            setProduct(prod);
        }
        // we're creating a new filter prod
        if (productId === 'new' && productIdInt === null) {
            getId();
        }
    }, [productIdInt, productId]);

    const getDuplicates = useCallback(
        (name, cid) => {
            if (products && products.length > 0) {
                return products.filter((prod) => prod.productName === name && prod.storageId !== cid);
            }
            return [];
        },
        [products]
    );

    useEffect(() => {
        const getUsage = async (cid) => {
            const data = await getProductUsage(cid);
            setProductUsage(data);
        };
        if (productId !== 'new' && products && products.length > 0) {
            const prod = products.find((prod) => prod.id === productIdInt);
            if (!prod) {
                setProductNotFound(true);
                return;
            }
            setProduct(prod);
            setDuplicates(getDuplicates(prod.productName, prod.storageId));
            getUsage(prod.storageId);
        }
    }, [products, getDuplicates, productId, productIdInt]);

    useEffect(() => {
        setProductDimensions(ParseProductDimension(product.productName));
        setProductNonStandard(!IsProductStandard(product.productName));
    }, [product.productName]);

    const close = () => {
        history.goBack();
    };

    const priceChanged = (evt) => {
        const value = evt.target.value;
        const sanitized = verifyAndReturnDecimal(value, 4);
        const prod = new FilterProduct(product);
        if (sanitized) {
            prod.priceWithoutVat = sanitized;
        } else {
            prod.priceWithoutVat = 0;
        }
        setProduct(prod);
    };
    const prodPriceChanged = (evt) => {
        const value = evt.target.value;
        const sanitized = verifyAndReturnDecimal(value, 4);
        const prod = new FilterProduct(product);
        if (sanitized) {
            prod.productionPrice = sanitized;
        } else {
            prod.productionPrice = 0;
        }
        setProduct(prod);
    };
    const areaChanged = (evt) => {
        const value = evt.target.value;
        const sanitized = verifyAndReturnDecimal(value, 4);
        const prod = new FilterProduct(product);
        if (sanitized) {
            prod.fabricSurfaceArea = sanitized;
        } else {
            prod.fabricSurfaceArea = 0;
        }
        setProduct(prod);
    };

    const handleChange = (evt) => {
        const value = evt.target.value.replace(/[\t]/g, '');
        const name = evt.target.name;
        const prod = new FilterProduct(product);
        let checkDupes = false;
        switch (name) {
            case 'price':
                prod.priceWithoutVat = value;
                break;
            case 'area':
                prod.fabricSurfaceArea = value;
                break;
            case 'productName':
                prod.productName = value;
                checkDupes = true;
                break;
            case 'productionPrice':
                prod.productionPrice = value;
                break;
            case 'type':
                prod.type = value;
                break;
            default:
                break;
        }
        if (checkDupes) {
            doDuplicateCheck(prod.productName, prod.storageId);
        }
        setProduct(prod);
    };

    const doDuplicateCheck = (name, cid) => {
        setDuplicates(getDuplicates(name, cid));
    };

    const save = async () => {
        const toSave = new FilterProduct(product);
        if (!toSave.productCode || toSave.productCode.length === 0) {
            toSave.productCode = '0';
        }
        setSaving(true);
        if (productId === 'new') {
            if (!(await saveNewProduct(toSave)(dispatch))) {
                toast.error(t('product.savingProductDataFailed'), {
                    autoClose: 5000,
                    hideProgressBar: false,
                });
            } else {
                setProductUsage([]);
                history.replace('/product/edit/' + productIdInt);
            }
        } else {
            if (!(await updateProduct(toSave)(dispatch))) {
                toast.error(t('product.savingProductDataFailed'), {
                    autoClose: 5000,
                    hideProgressBar: false,
                });
            }
        }
        setSaving(false);
    };

    const isDataOk = () => {
        return (
            !isNaN(parseFloat(product.priceWithoutVat)) &&
            parseFloat(product.priceWithoutVat) > 0 &&
            product.productName &&
            product.productName.length > 3 &&
            duplicates.length === 0
        );
    };

    const doDeleteProduct = async () => {
        setDeleting(true);
        setConfirmDeleteOpen(false);
        if (await deleteProduct(product.storageId)(dispatch)) {
            toast.info(t('product.productRemoved'), { autoClose: 2000, hideProgressBar: true });
            history.goBack();
        } else {
            toast.error(t('product.productRemovalFailed'), {
                autoClose: 5000,
                hideProgressBar: false,
            });
        }
    };
    const doMergeDuplicateProducts = async () => {
        setSaving(true);
        await mergeProducts(
            duplicates.map((d) => d.storageId),
            product.storageId
        )(dispatch);
        setSaving(false);
    };

    const doReplaceProduct = async () => {
        setSaving(true);
        const newProd = duplicates[0];
        await replaceProduct(product.storageId, newProd.storageId)(dispatch);
        history.replace('/products');
        setSaving(false);
    };

    const saveNote = (text, noteStorageId) => {
        if (!noteStorageId) {
            const prod = new FilterProduct(product);
            prod.addNote(text);
            setProduct(prod);
        } else {
            const prod = new FilterProduct(product);
            prod.setNoteText(text, noteStorageId);
            setProduct(prod);
        }

        setShowNoteDialog(false);
    };

    return (
        <Grid container>
            <Grid container item>
                <Grid item xs={12}>
                    <h5>
                        <Button onClick={close}>
                            <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                        </Button>
                        {productId === 'new' && <span>{t('product.newProduct')}</span>}
                        {productId !== 'new' && <span>{t('product.productEdit')}</span>}
                    </h5>
                </Grid>

                {!products && (
                    <Grid item className={classes.withPadding}>
                        <CircularProgress color={'secondary'} size={'2rem'} />
                    </Grid>
                )}
                {productNotFound && (
                    <Grid item xs={12} className={classes.withPadding}>
                        <Alert severity="error">{t('general.fetchFailedRetry')}</Alert>
                    </Grid>
                )}

                {product && product.status === FilterProduct.StatusDraftRequest() && (
                    <Grid item xs={12} className={classes.withPadding}>
                        <Alert severity="info">
                            {t('product.productRequestBySales', {
                                person: product.addedByUser,
                                addedDate: product.added.format('DD.MM.YYYY'),
                            })}
                            {t('product.hintSavingProductRemovesFromProductRequests')}
                        </Alert>
                    </Grid>
                )}

                {product && product.toBeReplaced && (
                    <Grid item xs={12} className={classes.withPadding}>
                        <Alert severity="info">
                            {t('product.hintProductMarkedToBeReplacedSavingRemovesFromRequests')}
                        </Alert>
                    </Grid>
                )}

                {duplicates.length > 0 && (
                    <Grid item xs={12} className={classes.withPadding}>
                        <Alert severity="warning">
                            {product.storageId &&
                                product.status === FilterProduct.StatusInUse() &&
                                !product.toBeReplaced && (
                                    <span>
                                        <strong>{product.productName}</strong> -&nbsp;
                                        {t('product.givenNameContainsMultipleProducts')}&nbsp;
                                        {t('product.hintProductsCanBeMerged')}
                                        <br />
                                        <SaveButton
                                            disabled={saving}
                                            hasChanges={false}
                                            onSubmit={doMergeDuplicateProducts}
                                            saving={saving}
                                            name="merge-save"
                                            text={t('order.merge')}></SaveButton>
                                    </span>
                                )}
                            {product.storageId &&
                                (product.status === FilterProduct.StatusDraftRequest() || product.toBeReplaced) && (
                                    <span>
                                        <strong>{product.productName}</strong> -&nbsp;
                                        {t('product.givenNameContainsMultipleProducts')}&nbsp;
                                        {t('product.hintThisProductRequestWillBeReplacedWithExistingProduct')}
                                        <br />
                                        <SaveButton
                                            disabled={saving}
                                            hasChanges={false}
                                            onSubmit={doReplaceProduct}
                                            saving={saving}
                                            name="replace-save"
                                            text={t('order.merge')}></SaveButton>
                                    </span>
                                )}
                            {!product.storageId && (
                                <span>
                                    <strong>{product.productName}</strong> -&nbsp;
                                    {t('product.givenNameContainsMultipleProducts')}&nbsp;
                                    {t('product.hintYouCanEditDuplicateProductsFromTheList')}
                                    <br />
                                </span>
                            )}
                            <br />
                            <strong>{t('general.products')}</strong>
                            <ul>
                                {duplicates.map((dup) => (
                                    <li key={dup.storageId}>
                                        <strong>
                                            {dup.status === FilterProduct.StatusDraftRequest() || dup.toBeReplaced
                                                ? t('product.productRequest')
                                                : ''}
                                        </strong>
                                        {dup.productName}, {t('invoice.priceVatZero')}: {dup.priceWithoutVat},{' '}
                                        {t('product.productionPrice')}: {dup.productionPrice}
                                    </li>
                                ))}
                            </ul>
                        </Alert>
                    </Grid>
                )}

                {products && !productNotFound && (
                    <Grid item container>
                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                error={duplicates.length > 0 || !product.productName}
                                id="productName"
                                name="productName"
                                label={t('general.name')}
                                value={product.productName}
                                className={classes.textField}
                                margin="normal"
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                error={false}
                                id="price"
                                name="price"
                                label={t('general.rrp')}
                                value={product.priceWithoutVat}
                                className={classes.textField}
                                margin="normal"
                                onBlur={priceChanged}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                error={false}
                                id="productionPrice"
                                name="productionPrice"
                                label={t('product.productionPrice')}
                                value={product.productionPrice}
                                className={classes.textField}
                                margin="normal"
                                onBlur={prodPriceChanged}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                required
                                error={false}
                                id="area"
                                name="area"
                                label={t('general.surfaceArea')}
                                value={product.fabricSurfaceArea}
                                className={classes.textField}
                                margin="normal"
                                onBlur={areaChanged}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <h5 className={classes.h5lessMargin}>{t('product.type')}</h5>
                            <span style={{ margin: '0.5em' }}> {product.type} </span>
                        </Grid>

                        <Grid item container>
                            <Grid item xs={12}>
                                <h5 className={classes.notesHeader}>{t('product.notes')}</h5>
                            </Grid>
                            <Grid item xs={12}>
                                {product.notes &&
                                    product.notes.length > 0 &&
                                    product.notes.map((note) => (
                                        <Chip
                                            key={note.text}
                                            className={classes.chip}
                                            icon={<EditIcon />}
                                            clickable={true}
                                            label={note.text}
                                            onClick={() => {
                                                setNoteToEdit(note);
                                                setShowNoteDialog(true);
                                            }}
                                        />
                                    ))}
                                <IconButton
                                    color="secondary"
                                    name="btn-add-note"
                                    aria-label="Lisää huomio"
                                    onClick={() => setShowNoteDialog(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <AddNoteDialog
                            open={showNoteDialog}
                            cancel={() => setShowNoteDialog(false)}
                            save={saveNote}
                            note={noteToEdit}
                            edit={!!noteToEdit}
                            suggestions={suggestions}
                        />

                        {productDimensions && (
                            <Alert
                                severity={productNonStandard ? 'warning' : 'info'}
                                className={classes.alertFullWidth}>
                                {t('product.productInformation')}: {t('product.width')}:{' '}
                                <i>{productDimensions.width}mm</i>, {t('product.height')}:{' '}
                                <i>{productDimensions.height}mm</i>, {t('product.depth')}:{' '}
                                <i>{productDimensions.depth}mm</i>. &nbsp;
                                {productNonStandard && <strong>{t('product.isANonStandardProduct')}</strong>}
                            </Alert>
                        )}
                        {productId !== 'new' && (
                            <Grid item xs={12} className={classes.withPadding}>
                                <strong>
                                    {t('product.inUseInLocations', {
                                        locationCount: productUsage ? productUsage.length : 0,
                                    })}
                                    <Button
                                        startIcon={
                                            !productUsage ? (
                                                <CircularProgress color={'secondary'} size={'1rem'} />
                                            ) : null
                                        }
                                        name="show-product-usage"
                                        onClick={() => setShowLocationsUsingProduct(!showLocationsUsingProduct)}>
                                        {t('buttons.show')}
                                    </Button>
                                </strong>
                            </Grid>
                        )}
                        {showLocationsUsingProduct && (
                            <Grid item container>
                                {productUsage.map((item) => (
                                    <Grid key={item.id} item xs={6} sm={4}>
                                        {item.name}
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>

            <ConfirmationDialog
                open={confirmDeleteOpen}
                confirm={doDeleteProduct}
                cancel={() => setConfirmDeleteOpen(false)}
                confirmText={'Poista'}>
                <span>{t('product.confirmRemoveProduct')}</span>
            </ConfirmationDialog>

            <Footer>
                <Grid item>
                    <DeleteButton
                        disabled={deleting || saving || productId === 'new' || productNotFound}
                        onSubmit={() => setConfirmDeleteOpen(true)}
                        deleting={deleting}
                    />
                </Grid>
                <Grid item>
                    <SaveButton
                        disabled={saving || deleting || !isDataOk() || productNotFound}
                        hasChanges={false}
                        onSubmit={save}
                        saving={saving}></SaveButton>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="secondary"
                        name="btn-close"
                        disabled={saving || deleting}
                        onClick={close}>
                        {t('buttons.close')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </Grid>
    );
}
