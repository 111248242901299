import { formatWeekIdentifierInt } from '../order/capacityplanner';
import { flatten } from 'lodash';

export function buildCapacityByPortfolioAndWeekData(capacityAllocationsByWeek, portfolios, weekList, changes) {
    let returnValue = {
        capacityPerPortfolio: {},
        urgentCapacity: {},
    };

    const capacityAllocations = flatten(
        Object.keys(capacityAllocationsByWeek).map((k) => capacityAllocationsByWeek[k])
    );
    if (portfolios && portfolios.length > 0 && capacityAllocations) {
        for (const p of portfolios.filter((p) => p.id !== 0)) {
            let capaPerPortfolio = {
                portfolioId: p.id,
                capacities: Object.fromEntries(
                    capacityAllocations
                        .filter((a) => a.portfolioId === p.id)
                        .map((a) => {
                            const key = formatWeekIdentifierInt(a.year, a.week);
                            return [key, a.allocatedCapacity];
                        })
                ),
            };
            for (const w of weekList) {
                if (typeof capaPerPortfolio.capacities[w] === 'undefined') {
                    capaPerPortfolio.capacities[w] = 0;
                }
            }
            returnValue.capacityPerPortfolio[p.id] = capaPerPortfolio;
        }
        returnValue.urgentCapacity = Object.fromEntries(
            capacityAllocations
                .filter((a) => a.portfolioId === 0)
                .map((a) => {
                    const key = formatWeekIdentifierInt(a.year, a.week);
                    return [key, a.allocatedCapacity];
                })
        );
    }

    // retain already modified values if given
    if (changes && changes.length > 0) {
        for (const c of changes) {
            if (c.portfolioId > 0) {
                returnValue.capacityPerPortfolio[c.portfolioId].capacities[formatWeekIdentifierInt(c.year, c.week)] =
                    c.allocatedCapacity;
            } else {
                returnValue.urgentCapacity[formatWeekIdentifierInt(c.year, c.week)] = c.allocatedCapacity;
            }
        }
    }
    return returnValue;
}

export function buildCapacityByProductTypeAndWeekData(capacityAllocationsByWeek, productTypes, weekList, changes) {
    let returnValue = {
        capacityPerProductType: {},
    };

    const capacityAllocations = flatten(
        Object.keys(capacityAllocationsByWeek).map((k) => capacityAllocationsByWeek[k])
    );
    if (productTypes && productTypes.length > 0 && capacityAllocations) {
        for (const p of productTypes) {
            let capaPerType = {
                productTypeId: p.id,
                capacities: Object.fromEntries(
                    capacityAllocations
                        .filter((a) => a.productType.id === p.id)
                        .map((a) => {
                            const key = formatWeekIdentifierInt(a.year, a.week);
                            return [key, a.allocatedCapacity];
                        })
                ),
            };
            for (const w of weekList) {
                if (typeof capaPerType.capacities[w] === 'undefined') {
                    capaPerType.capacities[w] = 0;
                }
            }
            returnValue.capacityPerProductType[p.id] = capaPerType;
        }
    }

    // retain already modified values if given
    if (changes && changes.length > 0) {
        for (const c of changes) {
            returnValue.capacityPerProductType[c.productType.id].capacities[formatWeekIdentifierInt(c.year, c.week)] =
                c.allocatedCapacity;
        }
    }
    return returnValue;
}
