class ProductType {
    constructor(json) {
        if (json) {
            this.id = json.id;
            this.name = json.name;
            this.regexp = json.regexp;
            this.additionalDeliveryDelayInDays = json.additionalDeliveryDelayInDays;
            this.minimumDeliveryTimeInDays = json.minimumDeliveryTimeInDays;
            this.removedFromProductCatalog = json.removedFromProductCatalog;
        } else {
            this.id = null;
            this.name = '';
            this.regexp = '';
        }
    }
}

export default ProductType;
