import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FooterPadding from '../main/footerpadding';
import Footer from '../main/footer';
import { makeStyles } from '@material-ui/core/styles';
import AddressInput from '../common/addressinput';
import EInvoiceInput from '../common/einvoiceinput';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import TextEditBlock from './texteditblock';
import InvoiceAttribute from './invoiceattribute';
import Invoice from '../domain/invoice';
import InvoiceRow from '../domain/invoicerow';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from 'date-fns/locale/fi';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as invActions from '../modules/invoicedata';
import { getCustomerByCustomerNumber } from '../modules/customerdata';
import { getLocationByCustomerNumber } from '../modules/locationdata';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FilterProductSelectDialog from '../location/filterproductselectdialog';
import CONFIG from '../config';
import { parseInteger, parseDecimal, objectsDiffer, verifyEmailAddress } from '../common/common';
import { round } from 'mathjs';
import SaveButton from '../common/savebutton';
import InvoiceEditRow from './invoiceeditrow';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sortBy, sumBy } from 'lodash';
import { toast } from 'react-toastify';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from '../common/confirmationdialog';
import ElectronicInvoiceInfo from '../domain/electronicinvoiceinfo';
import VatSelectorDialog from './vatselectordialog';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    btnContainer: {
        marginTop: theme.spacing(1),
    },
    indent: {
        marginLeft: theme.spacing(1),
    },
    table: {
        width: '100%',
    },
    accordion: {
        marginTop: '0.5em',
    },
}));

const fieldMaxLengths = {
    talenom: {
        payerBusinessId: 20,
        payerVatId: 20,
        ourReference: 35,
        customerReference: 35,
        payerName: 35,
        payerNameExt: 50,
        billingAddress: {
            streetAddress: 100,
            city: 30,
            zipCode: 50,
        },
        deliveryAddress: {
            name: 100,
            streetAddress: 100,
            city: 30,
            zipCode: 50,
        },
        electronicInvoiceAddress: {
            ovt: 35,
            operatorBrokerId: 35,
        },
        emailInvoiceAddress: 100,
    },
    netvisor: {
        payerBusinessId: 20,
        payerVatId: 20,
        ourReference: 200,
        customerReference: 200,
        payerName: 200,
        payerNameExt: 200,
        billingAddress: {
            streetAddress: 80,
            city: 80,
            zipCode: 50,
        },
        deliveryAddress: {
            name: 250,
            streetAddress: 80,
            city: 80,
            zipCode: 50,
        },
        electronicInvoiceAddress: {
            ovt: 50,
            operatorBrokerId: 50,
        },
        emailInvoiceAddress: 150,
    },
};

export default function InvoiceEditView(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [saving, setSaving] = useState(false);
    // const [formOk, setFormOk] = useState(true);
    const [invoice, setInvoice] = useState(null);
    const [editOpen, setEditOpen] = useState({});
    const [customerNameFromNumber, setCustomerNameFromNumber] = useState('');
    const [showProductSelector, setShowProductSelector] = useState(false);
    const fromStore = useSelector((state) => state.invoicedata.invoicesById[props.match.params.invoiceid]);
    const products = useSelector((state) => state.productdata.filterProducts);
    const invoiceParameters = useSelector((state) => state.settingsdata.invoiceParameters);
    const { t } = useTranslation();
    const customerNumberSearchTimer = React.useRef(null);
    const allowedEInvOperatorsNetvisor =
        invoiceParameters && invoice && invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor()
            ? invoiceParameters.eInvoiceOperatorsNetvisor.allowedOperatorBrokerIds
            : null;
    const vatIdsNetvisor = invoiceParameters ? invoiceParameters.invoiceVatParamsNetvisor.vatCodes : [];

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [vatChangeOpen, setVatChangeOpen] = useState(false);

    const triggerCustomerNameFromNumberCheck = React.useCallback(
        (custNumber) => {
            if (customerNumberSearchTimer.current) {
                clearTimeout(customerNumberSearchTimer.current);
                customerNumberSearchTimer.current = null;
            }
            customerNumberSearchTimer.current = setTimeout(async () => {
                const promises = [
                    getCustomerByCustomerNumber(custNumber)(dispatch),
                    getLocationByCustomerNumber(custNumber)(dispatch),
                ];
                const results = await Promise.all(promises);
                if (results[0]) {
                    setCustomerNameFromNumber(results[0].name);
                } else if (results[1]) {
                    setCustomerNameFromNumber(results[1].name);
                } else {
                    setCustomerNameFromNumber('');
                }
            }, 1000);
        },
        [dispatch]
    );

    useEffect(() => {
        if (!!fromStore) {
            triggerCustomerNameFromNumberCheck(fromStore.payerNumber);
            setInvoice(new Invoice(fromStore));
        }
    }, [fromStore, triggerCustomerNameFromNumberCheck]);

    const onCancel = () => {
        props.history.goBack();
    };

    const onSubmit = async () => {
        // if (!formOk) return; // should not come here, button disabled
        toast.info('Tallennetaan..', { autoClose: 1500, hideProgressBar: true });
        setSaving(true);
        const resp = await invActions.saveInvoice(invoice)(dispatch);
        if (resp) {
            toast.info(t('toast.invoiceSaved'), { autoClose: 1500, hideProgressBar: true });
        } else {
            toast.error(t('toast.saveFailed'), { autoClose: 5000 });
        }
        setSaving(false);
    };

    const addressChanged = (address, nameof) => {
        let newInv = new Invoice(invoice);
        newInv[nameof] = address;
        setInvoice(newInv);
    };
    const einvChanged = (einv) => {
        let newInv = new Invoice(invoice);
        newInv.electronicInvoiceAddress = einv;
        validateShipModeAndChangeIfNeeded(newInv);
        setInvoice(newInv);
    };
    const validateShipModeAndChangeIfNeeded = (inv) => {
        const possibleShipModes = [];
        if (inv.electronicInvoiceAddress && inv.electronicInvoiceAddress.isValid()) {
            possibleShipModes.push(Invoice.ShipModeEInv());
        }
        if (inv.emailInvoiceAddress && verifyEmailAddress(inv.emailInvoiceAddress)) {
            possibleShipModes.push(Invoice.ShipModeEMail());
        }
        possibleShipModes.push(Invoice.ShipModePaper());

        if (!possibleShipModes.includes(inv.shipMode)) {
            inv.shipMode = possibleShipModes[0];
        }
    };
    const onChange = (evt) => {
        let newInv = new Invoice(invoice);
        newInv[evt.target.name] = evt.target.value;
        if (evt.target.name === 'invoiceDnet') {
            newInv.dueDate = moment(newInv.invoiceDate).add('days', evt.target.value);
        } else if (evt.target.name === 'emailInvoiceAddress') {
            if (!verifyEmailAddress(evt.target.value)) {
                newInv.emailInvoiceAddress = null;
            }
        }
        validateShipModeAndChangeIfNeeded(newInv);
        setInvoice(newInv);
    };
    const onDateChange = (date, name) => {
        let newInv = new Invoice(invoice);
        newInv[name] = moment(date);
        setInvoice(newInv);
    };
    const shipModeChange = (evt) => {
        let newInv = new Invoice(invoice);
        newInv[evt.target.name] = parseInt(evt.target.value);
        setInvoice(newInv);
    };
    const factoringChange = (evt) => {
        let newInv = new Invoice(invoice);
        newInv.factoring = evt.target.checked ? 1 : undefined;
        setInvoice(newInv);
    };
    const addNewItem = () => {
        setShowProductSelector(true);
    };
    const productSelected = (id) => {
        setShowProductSelector(false);
        if (!!id) {
            const prod = products.filter((p) => p.id === id)[0];
            let newInv = new Invoice(invoice);
            newInv.rows.push(
                new InvoiceRow({
                    storageId: prod.id,
                    productNumber: prod.productCode,
                    productInfo: '',
                    productName: prod.productName,
                    quantity: 1,
                    unit: 'kpl',
                    pricePerUnit: prod.priceWithoutVat,
                    discountPercentage: 0.0,
                    vatRate: 24.0,
                    rowType: 0,
                    salesAccountNumber: CONFIG.defaultSalesAccountNumber,
                    priceVatExcluded: prod.priceWithoutVat,
                    priceVatIncluded: prod.priceWithoutVat * 1.24,
                    viewOrder: newInv.rows.length + 1,
                    productStorageId: prod.storageId,
                    vatHandling:
                        invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor() ? 'KOMY' : '',
                })
            );
            newInv.updateTotalPrices();
            setInvoice(newInv);
        }
    };
    const editRow = (id) => {
        let newValue = editOpen;
        newValue = {};
        newValue[id] = true;
        setEditOpen(newValue);
    };
    const closeRowEdit = (event) => {
        event.stopPropagation();
        setEditOpen({});
    };
    const onChangePricePerUnit = (id, event) => {
        let newInv = new Invoice(invoice);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.pricePerUnit = parseDecimal(event.target.value);
        newInv.updateTotalPrices();
        setInvoice(newInv);
    };
    const onChangeQuantity = (id, event) => {
        let newInv = new Invoice(invoice);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.quantity = parseInteger(event.target.value);
        newInv.updateTotalPrices();
        setInvoice(newInv);
    };
    const onChangeProductInfo = (id, evt) => {
        let newInv = new Invoice(invoice);
        console.log(evt);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.productInfo = evt.target.value;
        setInvoice(newInv);
    };
    const onChangeVatRate = (id, event) => {
        let newInv = new Invoice(invoice);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.vatRate = round(parseDecimal(event.target.value), 2);
        if (invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom()) {
            if (row.vatRate !== 24 && row.vatRate !== 0) {
                row.vatRate = 24;
            }
            if (row.vatRate === 0) {
                row.salesAccountNumber = row.salesAccountOptions()[1].value;
            } else if (row.vatRate === 24) {
                row.salesAccountNumber = row.salesAccountOptions()[0].value;
            }
        } else if (invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor()) {
            const vatCode = vatIdsNetvisor.find((c) => c.vatCode === row.vatHandling);
            if (!vatCode.allowedVatPercentages.includes(row.vatRate)) {
                row.vatRate = vatCode.allowedVatPercentages[vatCode.allowedVatPercentages.length - 1];
            }
        } else {
            throw new Error('tuntematon laskutusintegraatio. Data viallinen');
        }
        newInv.updateTotalPrices();
        setInvoice(newInv);
    };
    const onChangeVatHandling = (id, event) => {
        let newInv = new Invoice(invoice);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.vatHandling = event.target.value;
        const vatCode = vatIdsNetvisor.find((c) => c.vatCode === row.vatHandling);
        if (!vatCode) {
            throw new Error('ei löydy ALV koodia');
        } else {
            row.vatRate = vatCode.allowedVatPercentages[vatCode.allowedVatPercentages.length - 1];
        }
        newInv.updateTotalPrices();
        setInvoice(newInv);
    };
    const onChangeSalesAccount = (id, event) => {
        let newInv = new Invoice(invoice);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.salesAccountNumber = event.target.value;
        if (row.salesAccountNumber === row.salesAccountOptions()[0].value) {
            row.vatRate = 24;
        } else {
            row.vatRate = 0;
        }
        newInv.updateTotalPrices();
        setInvoice(newInv);
    };
    const onChangeCustomerNumber = (event) => {
        let newInv = new Invoice(invoice);
        const custNumber = parseInteger(event.target.value);
        if (custNumber > 0) {
            triggerCustomerNameFromNumberCheck(custNumber);
            newInv.payerNumber = custNumber;
        }
        setInvoice(newInv);
    };
    const addNewInfoRow = () => {
        const newInv = new Invoice(invoice);
        const newRow = new InvoiceRow({
            storageId: Math.floor(Math.random() * 1000000),
            rowType: 1,
            productNumber: '',
            productName: '',
            viewOrder: 0,
        });
        newInv.rows.splice(0, 0, newRow);
        const newEditOpen = editOpen;
        newEditOpen[newRow.storageId] = true;
        resetRowViewOrder(newInv);
        setInvoice(newInv);
        setEditOpen(newEditOpen);
    };
    const onRemoveRow = (id) => {
        const newInv = new Invoice(invoice);
        newInv.rows.splice(
            newInv.rows.findIndex((r) => r.storageId === id),
            1
        );
        resetRowViewOrder(newInv);
        setInvoice(newInv);
    };
    const resetRowViewOrder = (inv) => {
        let ord = 0;
        inv.rows = sortBy(inv.rows, ['viewOrder']);
        for (const r of inv.rows) {
            r.viewOrder = ord;
            ord++;
        }
    };
    const onChangeProductName = (id, evt) => {
        const newInv = new Invoice(invoice);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.productName = evt.target.value;
        setInvoice(newInv);
    };
    const onChangeProductNumber = (id, evt) => {
        const newInv = new Invoice(invoice);
        const row = newInv.rows.find((r) => r.storageId === id);
        row.productNumber = evt.target.value;
        setInvoice(newInv);
    };
    const GetHeader = () => {
        if (invoice == null) return null;
        return (
            <h2>
                <Button onClick={onCancel}>
                    <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                </Button>
                {t('general.invoice')}:&nbsp;{invoice.invoiceNumber}
            </h2>
        );
    };

    const hasChanges = () => {
        return objectsDiffer(fromStore, invoice);
    };

    const addDelivery = () => {
        const newInv = new Invoice(invoice);
        const newRow = giveEmptyInvoiceRow(newInv);
        newRow.productNumber = 'R';
        newRow.productName = 'RAHTI';
        newRow.storageId = 1;
        newInv.rows.push(newRow);
        setInvoice(newInv);
    };

    const addDeliveryAndPackagingContractPrice = () => {
        const newInv = new Invoice(invoice);
        const newRow = giveEmptyInvoiceRow(newInv);
        newRow.productNumber = 'RP';
        newRow.productName = 'RAHTI JA PAKKAUS / SOPIMUSHINTA';
        newRow.storageId = 2;
        newInv.rows.push(newRow);
        setInvoice(newInv);
    };

    const addDeliveryToPickupPoint = () => {
        const newInv = new Invoice(invoice);
        const newRow = giveEmptyInvoiceRow(newInv);
        newRow.productNumber = 'RN';
        newRow.productName = 'RAHTI NOUTOPISTEESEEN';
        newRow.storageId = 3;
        newInv.rows.push(newRow);
        setInvoice(newInv);
    };

    const giveEmptyInvoiceRow = (newInv) => {
        return new InvoiceRow({
            storageId: null,
            productNumber: '',
            productInfo: '',
            productName: '',
            quantity: 1,
            unit: 'kpl',
            pricePerUnit: 0,
            discountPercentage: 0.0,
            vatRate: 24.0,
            rowType: 0,
            salesAccountNumber: CONFIG.defaultSalesAccountNumber,
            priceVatExcluded: 0,
            priceVatIncluded: 0,
            viewOrder: newInv.rows.length + 1,
            productStorageId: null,
            vatHandling: 'KOMY', // Kotimaan myynti by default
        });
    };

    const confirmDeleteEinvAddress = () => {
        const newInv = new Invoice(invoice);
        newInv.electronicInvoiceAddress = null;
        setInvoice(newInv);
        setConfirmOpen(false);
    };

    const addEinvAddress = () => {
        const newInv = new Invoice(invoice);
        newInv.electronicInvoiceAddress = new ElectronicInvoiceInfo();
        setInvoice(newInv);
    };

    const gatherVatInfos = (rows) => {
        const vat10 = sumBy(
            rows.filter((r) => r.vatRate === 10.0),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );
        const vat14 = sumBy(
            rows.filter((r) => r.vatRate === 14.0),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );
        const vat24 = sumBy(
            rows.filter((r) => r.vatRate === 24.0),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );
        const vat255 = sumBy(
            rows.filter((r) => r.vatRate === 25.5),
            (r) => r.priceVatIncluded - r.priceVatExcluded
        );

        let results = [
            { vatRate: 10.0, vatAmount: round(vat10, 2) },
            { vatRate: 14.0, vatAmount: round(vat14, 2) },
            { vatRate: 24.0, vatAmount: round(vat24, 2) },
            { vatRate: 25.5, vatAmount: round(vat255, 2) },
        ];
        return results.filter((r) => r.vatAmount > 0);
    };

    const confirmChangeVatRate = (handling, rate) => {
        setVatChangeOpen(false);

        const vatItem = invoiceParameters.invoiceVatParamsNetvisor.vatCodes.find((i) => i.vatCode === handling);
        if (vatItem && vatItem.allowedVatPercentages.includes(rate)) {
            // valid data, update rows.
            const newInv = new Invoice(invoice);
            for (const row of newInv.rows) {
                row.vatHandling = handling;
                row.vatRate = rate;
            }
            setInvoice(newInv);
        }
    };

    if (!invoice) return null;

    return (
        <div>
            <fieldset disabled={saving}>
                <Grid container>
                    <Grid item xs={12}>
                        <GetHeader />
                    </Grid>

                    {invoice.status === Invoice.InvoiceStatusSent() && (
                        <Grid item xs={12}>
                            <Alert severity="warning">{t('invoice.hintEditingSentInvoice')}</Alert>
                        </Grid>
                    )}

                    <InvoiceAttribute name={t('general.portfolio')} value={invoice.portfolio.name} />
                    <InvoiceAttribute name={t('invoice.invoiceNumber')} value={invoice.invoiceNumber} />
                    <InvoiceAttribute name={t('general.language')} value={invoice.invoiceLanguage} />
                    <InvoiceAttribute name={t('general.orderNumber')} value={invoice.orderNumber} />
                    {invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationNetvisor() && (
                        <InvoiceAttribute name={t('invoice.dueDate')} value={invoice.dueDate.format('DD.MM.YYYY')} />
                    )}
                    <InvoiceAttribute name={t('invoice.referenceNumber')} value={invoice.referenceNumberFormatted()} />
                    <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                name="deliveryDate"
                                label={t('general.orderDate')}
                                value={invoice.orderDate}
                                onChange={(date) => onDateChange(date, 'orderDate')}
                                invalidDateMessage={t('general.invalidDateMsg')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                name="deliveryDate"
                                label={t('general.deliveryDate')}
                                value={invoice.deliveryDate}
                                onChange={(date) => onDateChange(date, 'deliveryDate')}
                                invalidDateMessage={t('general.invalidDateMsg')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                            <KeyboardDatePicker
                                disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                name="invoiceDate"
                                label={t('invoice.invoiceDate')}
                                value={invoice.invoiceDate}
                                onChange={(date) => onDateChange(date, 'invoiceDate')}
                                invalidDateMessage={t('general.invalidDateMsg')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    autoOk={true}
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    margin="normal"
                                    name="dueDate"
                                    label={t('invoice.dueDate')}
                                    value={invoice.dueDate}
                                    onChange={(date) => onDateChange(date, 'dueDate')}
                                    invalidDateMessage={t('general.invalidDateMsg')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        )}
                    </Grid>

                    <TextEditBlock
                        id={'payerNumber'}
                        name={'payerNumber'}
                        label={t('invoice.payer') + ' ' + t('general.customerNumber')}
                        value={invoice.payerNumber ? invoice.payerNumber : ''}
                        onChange={onChangeCustomerNumber}
                        required={true}
                        showCounter={false}
                        sidenote={customerNameFromNumber}
                    />

                    <TextEditBlock
                        name={'payerBusinessId'}
                        label={t('invoice.payer') + ' ' + t('general.businessId')}
                        value={invoice.payerBusinessId ? invoice.payerBusinessId : ''}
                        onChange={onChange}
                        maxLength={fieldMaxLengths[invoice.salesInvoiceIntegration].payerBusinessId}
                        required={true}
                        showCounter={true}
                    />
                    <TextEditBlock
                        name={'payerVatId'}
                        label={t('invoice.payer') + ' ' + t('general.vatId')}
                        value={invoice.payerVatId ? invoice.payerVatId : ''}
                        onChange={onChange}
                        maxLength={fieldMaxLengths[invoice.salesInvoiceIntegration].payerVatId}
                        showCounter={true}
                    />
                    <TextEditBlock
                        name={'ourReference'}
                        label={t('parameterCategories.OurReference')}
                        value={invoice.ourReference}
                        onChange={onChange}
                        maxLength={fieldMaxLengths[invoice.salesInvoiceIntegration].ourReference}
                        required={true}
                        showCounter={true}
                    />
                    <TextEditBlock
                        name={'customerReference'}
                        label={t('invoice.customerReference')}
                        value={invoice.customerReference}
                        onChange={onChange}
                        maxLength={fieldMaxLengths[invoice.salesInvoiceIntegration].customerReference}
                        required={true}
                        showCounter={true}
                    />
                    <TextEditBlock
                        name={'invoiceDnet'}
                        label={t('invoice.termsDnet')}
                        value={invoice.invoiceDnet}
                        onChange={onChange}
                        number={true}
                        required={true}
                    />
                    <TextEditBlock
                        name={'overdueInterest'}
                        label={t('invoice.overdueInterest')}
                        value={invoice.overdueInterest}
                        onChange={onChange}
                        number={true}
                        required={true}
                    />
                    {invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                        <TextEditBlock
                            name={'remarkTime'}
                            label={t('invoice.remarkTimeDays')}
                            value={invoice.remarkTime}
                            onChange={onChange}
                            number={true}
                            required={true}
                        />
                    )}

                    {invoice.salesInvoiceIntegration === Invoice.SalesInvoiceIntegrationTalenom() && (
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('invoice.shipMode')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="shipMode"
                                    name="shipMode"
                                    value={invoice.shipMode}
                                    onChange={shipModeChange}>
                                    <FormControlLabel
                                        labelPlacement="bottom"
                                        value={Invoice.ShipModeEInv()}
                                        disabled={
                                            !invoice.electronicInvoiceAddress ||
                                            !invoice.electronicInvoiceAddress.isValid()
                                        }
                                        control={<Radio />}
                                        label={t('invoice.eInvoice')}
                                    />
                                    <FormControlLabel
                                        labelPlacement="bottom"
                                        value={Invoice.ShipModePaper()}
                                        control={<Radio />}
                                        label={t('invoice.paperInvoice')}
                                    />
                                    <FormControlLabel
                                        labelPlacement="bottom"
                                        value={Invoice.ShipModeEMail()}
                                        disabled={
                                            !invoice.emailInvoiceAddress || invoice.emailInvoiceAddress.length === 0
                                        }
                                        control={<Radio />}
                                        label={t('invoice.emailInvoice')}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('invoice.toFinanceCompany')}</FormLabel>
                                <Checkbox
                                    checked={invoice.factoring === 1}
                                    onChange={factoringChange}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>{t('general.invoicingAddress')}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <TextEditBlock
                                        name={'payerName'}
                                        label={t('invoice.payerName')}
                                        value={invoice.payerName}
                                        onChange={onChange}
                                        maxLength={fieldMaxLengths[invoice.salesInvoiceIntegration].payerName}
                                        required={true}
                                        showCounter={true}
                                    />

                                    <TextEditBlock
                                        name={'payerNameExt'}
                                        label={t('invoice.payerNameExt')}
                                        value={invoice.payerNameExt}
                                        onChange={onChange}
                                        maxLength={fieldMaxLengths[invoice.salesInvoiceIntegration].payerNameExt}
                                        showCounter={true}
                                    />

                                    <Grid item xs={12}>
                                        <AddressInput
                                            addressChanged={(address) => addressChanged(address, 'billingAddress')}
                                            hideName={true}
                                            address={invoice.billingAddress}
                                            idBase={'invoice'}
                                            fieldConfig={{
                                                streetAddress: {
                                                    showCounter: true,
                                                    maxLength:
                                                        fieldMaxLengths[invoice.salesInvoiceIntegration].billingAddress
                                                            .streetAddress,
                                                },
                                                zipCode: {
                                                    showCounter: true,
                                                    maxLength:
                                                        fieldMaxLengths[invoice.salesInvoiceIntegration].billingAddress
                                                            .zipCode,
                                                },
                                                city: {
                                                    showCounter: true,
                                                    maxLength:
                                                        fieldMaxLengths[invoice.salesInvoiceIntegration].billingAddress
                                                            .city,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>{t('general.deliveryAddress')}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <AddressInput
                                    addressChanged={(address) => addressChanged(address, 'deliveryAddress')}
                                    address={invoice.deliveryAddress}
                                    idBase={'delivery'}
                                    fieldConfig={{
                                        name: {
                                            showCounter: true,
                                            maxLength:
                                                fieldMaxLengths[invoice.salesInvoiceIntegration].deliveryAddress.name,
                                        },
                                        streetAddress: {
                                            showCounter: true,
                                            maxLength:
                                                fieldMaxLengths[invoice.salesInvoiceIntegration].deliveryAddress
                                                    .streetAddress,
                                        },
                                        zipCode: {
                                            showCounter: true,
                                            maxLength:
                                                fieldMaxLengths[invoice.salesInvoiceIntegration].deliveryAddress
                                                    .zipCode,
                                        },
                                        city: {
                                            showCounter: true,
                                            maxLength:
                                                fieldMaxLengths[invoice.salesInvoiceIntegration].deliveryAddress.city,
                                        },
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>{t('general.eInvoiceAddress')}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                {!invoice.electronicInvoiceAddress && (
                                    <Grid>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            name="add-einv"
                                            onClick={addEinvAddress}
                                            disabled={saving}
                                            startIcon={<AddBoxIcon />}>
                                            {t('buttons.add')}
                                        </Button>
                                    </Grid>
                                )}
                                {invoice.electronicInvoiceAddress && (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                name="remove-einv"
                                                onClick={() => {
                                                    setConfirmOpen(true);
                                                }}
                                                disabled={saving}
                                                startIcon={<DeleteIcon />}>
                                                {t('buttons.remove')}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <EInvoiceInput
                                                einvoiceinfo={invoice.electronicInvoiceAddress}
                                                idBase={'invoice'}
                                                einvoiceinfoChanged={einvChanged}
                                                allowedEInvOperators={allowedEInvOperatorsNetvisor}
                                                fieldConfig={{
                                                    ovt: {
                                                        showCounter: true,
                                                        maxLength:
                                                            fieldMaxLengths[invoice.salesInvoiceIntegration]
                                                                .electronicInvoiceAddress.ovt,
                                                    },
                                                    operatorBrokerId: {
                                                        showCounter: true,
                                                        maxLength:
                                                            fieldMaxLengths[invoice.salesInvoiceIntegration]
                                                                .electronicInvoiceAddress.operatorBrokerId,
                                                    },
                                                    operator: { hide: true },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <Accordion className={classes.accordion}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h4>{t('invoice.emailInvoiceAddress')}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextEditBlock
                                    name={'emailInvoiceAddress'}
                                    label={t('invoice.emailInvoiceAddress')}
                                    value={invoice.emailInvoiceAddress}
                                    onChange={onChange}
                                    maxLength={fieldMaxLengths[invoice.salesInvoiceIntegration].emailInvoiceAddress}
                                    required={true}
                                    showCounter={true}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="locationedit-container">
                            <h4>{t('general.products')}</h4>
                            <Table size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('product.code')}</TableCell>
                                        <TableCell>{t('product.name')}</TableCell>
                                        <TableCell>{t('pcs')}</TableCell>
                                        <TableCell>{t('invoice.pricePerUnit')}</TableCell>
                                        <TableCell>{t('invoice.vatPercentage')}</TableCell>
                                        <TableCell>{t('invoice.priceVatZero')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoice.rows.map((row) => (
                                        <InvoiceEditRow
                                            key={row.storageId}
                                            row={row}
                                            edit={!!editOpen[row.storageId]}
                                            editRow={editRow}
                                            onChangeQuantity={onChangeQuantity}
                                            onChangePricePerUnit={onChangePricePerUnit}
                                            closeRowEdit={closeRowEdit}
                                            saving={saving}
                                            onChangeProductInfo={onChangeProductInfo}
                                            onRemoveRow={onRemoveRow}
                                            onChangeProductName={onChangeProductName}
                                            onChangeProductNumber={onChangeProductNumber}
                                            onChangeVatRate={onChangeVatRate}
                                            onChangeSalesAccount={onChangeSalesAccount}
                                            onChangeVatHandling={onChangeVatHandling}
                                            salesInvoiceIntegration={invoice.salesInvoiceIntegration}
                                            vatCodes={vatIdsNetvisor}
                                        />
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={7}>
                                            {!showProductSelector && (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    name="newitem"
                                                    onClick={addNewItem}
                                                    startIcon={<AddBoxIcon />}
                                                    disabled={saving}>
                                                    {t('general.product')}
                                                </Button>
                                            )}
                                            &nbsp;
                                            {!showProductSelector && (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    name="newitem"
                                                    onClick={addNewInfoRow}
                                                    startIcon={<AddBoxIcon />}
                                                    disabled={saving}>
                                                    {t('general.definition')}
                                                </Button>
                                            )}
                                            &nbsp;
                                            {!showProductSelector && (
                                                <Tooltip title={t('invoice.addDeliveryCost')}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        name="adddeliverycharge"
                                                        onClick={addDelivery}
                                                        startIcon={<AddBoxIcon />}
                                                        disabled={saving}>
                                                        R
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            &nbsp;
                                            {!showProductSelector && (
                                                <Tooltip title={t('invoice.addDeliveryAndPackagingCostContract')}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        name="adddeliverychargescontractprice"
                                                        onClick={addDeliveryAndPackagingContractPrice}
                                                        startIcon={<AddBoxIcon />}
                                                        disabled={saving}>
                                                        RP
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            &nbsp;
                                            {!showProductSelector && (
                                                <Tooltip title={t('invoice.addDeliveryCostToPickupPoint')}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        name="adddeliverytopickuppoint"
                                                        onClick={addDeliveryToPickupPoint}
                                                        startIcon={<AddBoxIcon />}
                                                        disabled={saving}>
                                                        RN
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            &nbsp;
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                name="switchvatrates"
                                                onClick={() => {
                                                    setVatChangeOpen(true);
                                                }}
                                                startIcon={<SwapHorizIcon />}
                                                disabled={saving}>
                                                {t('invoice.vatPercentage')}
                                            </Button>
                                            {showProductSelector && (
                                                <FilterProductSelectDialog
                                                    title={t('general.selectProduct')}
                                                    show={showProductSelector}
                                                    selected={productSelected}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <strong>{t('invoice.totalPriceVatZero')}</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>{round(invoice.vatExcluded, 2)}</strong>
                                        </TableCell>
                                    </TableRow>
                                    {gatherVatInfos(invoice.rows).map((info) => (
                                        <TableRow key={info.vatRate}>
                                            <TableCell colSpan={5}>{t('general.vatShort')}</TableCell>
                                            <TableCell>{info.vatRate} %</TableCell>
                                            <TableCell>
                                                <strong>{info.vatAmount}</strong>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <strong>{t('general.vatShort')}</strong>
                                        </TableCell>
                                        <TableCell>{t('reports.altogether')}</TableCell>
                                        <TableCell>
                                            <strong>{round(invoice.vat, 2)}</strong>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={6}>
                                            <strong>{t('invoice.totalPriceVatIncl')}</strong>
                                        </TableCell>
                                        <TableCell>
                                            <strong>{round(invoice.vatIncluded, 2)}</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </Grid>

                    <ConfirmationDialog
                        open={confirmOpen}
                        confirm={confirmDeleteEinvAddress}
                        cancel={() => {
                            setConfirmOpen(false);
                        }}
                        confirmText={t('buttons.remove')}>
                        <span>{t('invoice.confirmDeleteEInvAddress')}</span>
                    </ConfirmationDialog>

                    <Footer>
                        <Grid item>
                            <SaveButton
                                hasChanges={hasChanges()}
                                disabled={saving || Object.keys(editOpen).length > 0}
                                onSubmit={onSubmit}
                                saving={saving}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                name="close"
                                onClick={onCancel}
                                disabled={saving}
                                startIcon={<CloseIcon />}>
                                {t('buttons.close')}
                            </Button>
                        </Grid>
                    </Footer>
                </Grid>
            </fieldset>
            <FooterPadding />
            <VatSelectorDialog
                open={vatChangeOpen}
                vatInfos={invoiceParameters.invoiceVatParamsNetvisor.vatCodes}
                confirm={confirmChangeVatRate}
                cancel={() => {
                    setVatChangeOpen(false);
                }}
            />
        </div>
    );
}
