import moment from 'moment';
import {
    removeCapacityReservationsForOrder,
    getEarliestDeliveryDate,
    makeCapacityReservations,
    getEarliestDeliveryDateWithPayload,
    updateCapacityReservations,
} from '../modules/prodplanningdata';
import { maxBy } from 'lodash';
import Logger from '../common/logger';

const dayjs = require('dayjs');
var isoWeek = require('dayjs/plugin/isoWeek');
var advancedFormat = require('dayjs/plugin/advancedFormat');
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
require('dayjs/locale/fi');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Delivery delay in weekdays for VPF products
//const DeliveryDelayInDaysVPF = 2;

// Delivery delay in weekdays for VMP and VCP products
//const DeliveryDelayInDaysVMP = 5;

// Skip capacity handling for these customers altogether
const SkipCapacityHandlingCustomers = [29739, 20892, 29477]; // order.customer.customerNumber

// default delay for other products
//const DeliveryDelayInDaysDefault = 5;

export function generateContinuousWeekList(startWeek, endWeek, inclusive = true) {
    if (!inclusive) throw new Error('param error');

    let weeks = [];
    const startY = getYearFromIdentifier(startWeek);
    const startW = getWeekFromIdentifier(startWeek);

    let year = startY;
    let week = startW;
    let weeksInYear = moment(`${startY}-01-01`).weeksInYear();
    let id = '';
    do {
        id = formatWeekIdentifierInt(year, week);
        weeks.push(`${id}`);
        if (week === weeksInYear) {
            year++;
            week = 1;
            weeksInYear = moment(`${year}-01-01`).weeksInYear();
        } else {
            week++;
        }
    } while (id < endWeek);
    return weeks;
}

export function getWeekFromIdentifier(id) {
    const week = id.split('-')[1];
    return parseInt(week);
}

export function getYearFromIdentifier(id) {
    const year = id.split('-')[0];
    return parseInt(year);
}

export function formatWeekIdentifierDayjs(dayjsObj) {
    return dayjsObj.format('YYYY-WW');
}

export function formatWeekIdentifierMoment(momentObj) {
    const dj = dayjs(momentObj.format());
    const ret = dj.format('YYYY-WW');
    return ret;
}

export function formatWeekIdentifierInt(year, week) {
    if (week < 10) week = `0${week}`;
    return `${year}-${week}`;
}

export async function earliestDeliveryDateForOrder(store, orders) {
    let dates = {};
    try {
        dates = await getEarliestDeliveryDate(orders.map((o) => o.id));
    } catch (err) {
        console.log(err);
        return null;
    }
    return dates;
}

export async function earliestDeliveryDateWithOrderPayload(orders) {
    try {
        return await getEarliestDeliveryDateWithPayload(orders);
    } catch (err) {
        console.log(err);
        return null;
    }
}

export async function productTypeMakeCapacityReservations(store, orders, estimatedDeliveryDate) {
    if (shallBypassCapacityHandling(orders[0])) return [];

    const earliestDates = await earliestDeliveryDateForOrder(store, orders);
    if (earliestDates.find((o) => o.earliestDeliveryDate === null)) {
        throw new Error('Invalid data, cannot determine earliest delivery date');
    }

    const maxItem = maxBy(earliestDates, (o) => dayjs(o.earliestDeliveryDate).valueOf());
    if (!dayjs(maxItem.earliestDeliveryDate).isSameOrBefore(dayjs(estimatedDeliveryDate), 'day')) {
        Logger.LogError(
            `Trying to set deliverydate earlier than possible, earliest: ${maxItem.earliestDeliveryDate}, input:${estimatedDeliveryDate}`
        );
        throw new Error('deliverydate too early');
    }

    try {
        await makeCapacityReservations(
            orders.map((o) => o.id),
            estimatedDeliveryDate
        );
        return true;
    } catch (err) {
        return false;
    }
}

export async function removeCapacityReservations(store, orderId) {
    await removeCapacityReservationsForOrder(orderId)(store.dispatch);
}

export async function productTypeUpdateCapacityReservationsIfNeeded(store, order, deliveryDate) {
    try {
        var res = await updateCapacityReservations([order.id], deliveryDate);
        return res;
    } catch (err) {
        return false;
    }
}

function shallBypassCapacityHandling(order) {
    if (!order) return false;
    return SkipCapacityHandlingCustomers.includes(order.customer.customerNumber);
}
