import ProductType from './producttype';

class ProductTypeCapacityAllocation {
    constructor(json) {
        if (json) {
            this.productType = new ProductType(json.productType);
            this.year = json.year;
            this.week = json.week;
            this.allocatedCapacity = json.allocatedCapacity;
        } else {
            this.productType = new ProductType();
            this.year = 0;
            this.week = 0;
            this.allocatedCapacity = 0;
        }
    }
}

export default ProductTypeCapacityAllocation;
